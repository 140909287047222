import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../layouts/Layout';
import Section from '../components/shared/wraps/Section/Section';
import BigTitle from '../components/shared/text/BigTitle';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import FadeLink from '../components/shared/navigations/FadeLink/FadeLink';
import SEO from '../components/seo';

const StyledFadeLink = styled(FadeLink)`
  overflow: hidden;
`;

const StyledImage = styled(Image)`
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
  picture img {
    transform-origin: center;
    transition: transform 0.5s ease, opacity 0.5s ease 0s !important;
  }
  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: 0.4;
    transition: opacity 0.5s ease 0.2s;
  }
  :hover {
    picture img {
      transform: scale(1.1);
    }
    ::after {
      opacity: 0.1;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  font-size: 8rem;
  margin-bottom: 3.5rem;
`;

export const galleryQuery = graphql`
  {
    allDirectusProjekty {
      nodes {
        title
        slug
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const Projects = ({ data, location: { pathname: path } }) => {
  return (
    <Layout path={path} id="projekty">
      <SEO title="Projekty" />
      <Section first nonGrid site nonFlex>
        <StyledBigTitle>Projekty</StyledBigTitle>

        <ResponsiveMasonry
          columnsCountBreakPoints={{ 250: 1, 591: 3, 1023: 5 }}
        >
          <Masonry gutter="0.5rem">
            {data.allDirectusProjekty.nodes.map(image => (
              <StyledFadeLink key={image.title} to={`/projekty/${image.slug}`}>
                <StyledImage
                  fluid={image.preview.localFile.childImageSharp.fluid}
                  alt={image.title}
                />
              </StyledFadeLink>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Section>
    </Layout>
  );
};

export default Projects;
